import { ResponsiveBar } from '@nivo/bar';
import React from 'react';


export default function BarChart(props) {
    
    const datesLabels = []
    let numberOfDaysInTempMonth 
    
    if (props.statsEndDate=== undefined) {
        numberOfDaysInTempMonth = 31
    } else {
        numberOfDaysInTempMonth = parseInt(props.statsEndDate.slice(-2))
    }
    
    const errorStatsStartDate = "2023-01-01" 
    const errorStatsEndDate = "2023-01-31"
    if ( props.statsEndDate === undefined || props.statsStartDate === undefined) {
        for (let i=0; i<numberOfDaysInTempMonth; i++ ) {
            datesLabels.push(`${errorStatsStartDate.slice(0,-2)}${i<9 ? "0" + (parseInt(errorStatsStartDate.slice(-2))+i) : parseInt(errorStatsStartDate.slice(-2))+i}`)
        }
    } else {
        for (let i=0; i<numberOfDaysInTempMonth; i++ ) {
            datesLabels.push(`${props.statsStartDate.slice(0,-2)}${i<9 ? "0" + (parseInt(props.statsStartDate.slice(-2))+i) : parseInt(props.statsStartDate.slice(-2))+i}`)
        }
    }

    const tempDisplayData = props.dataForCharBar.map((item) => (item["call_data_f"].slice(0,10)))  
    
    const finalDisplayData =[]
    let tempCount = 0
    for (let i=0; i<datesLabels.length;i++) {
        finalDisplayData.push (
            {day: datesLabels[i].slice(8), events: tempDisplayData.filter( (item) => item === datesLabels[i]).length}
        )
    }
    
    return (
        <ResponsiveBar
        data={finalDisplayData}
        keys={[
            'events',
        ]}
        indexBy="day"
        margin={{ top: 30, right: 30, bottom: 50, left: 22 }}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={{ scheme: 'accent' }}
        defs={[
            {
                id: 'dots',
                type: 'patternDots',
                background: 'inherit',
                color: '#38bcb2',
                size: 4,
                padding: 1,
                stagger: true
            },
            {
                id: 'lines',
                type: 'patternLines',
                background: 'inherit',
                color: '#eed312',
                rotation: -45,
                lineWidth: 6,
                spacing: 10
            }
        ]}
        fill={[
            {
                match: {
                    id: 'fries'
                },
                id: 'dots'
            },
            {
                match: {
                    id: 'sandwich'
                },
                id: 'lines'
            }
        ]}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    '1'
                ]
            ]
        }}
        axisTop={null}
        axisRight={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: '',
            tickValues:[0,1,3,5,10,15,20,25,30,35,40,45,50],
            legendOffset: 0
        }}
        axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Day of the month',
            legendPosition: 'middle',
            legendOffset: 44
        }}
        axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendPosition: 'middle',
            tickValues:[0,1,3,5,10,15,20,25,30,35,40,45,50],
            legendOffset: -40
        }}
        enableLabel={false}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    1.6
                ]
            ]
        }}
        legends={[]}
        motionConfig="gentle"
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={function(e){return e.id+": "+e.formattedValue+" in country: "+e.indexValue}}
    />
    )}


        