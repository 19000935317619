import React from 'react';
import { statsSms} from '../api/vndApi';
import { useQuery} from '@tanstack/react-query';
import StatsTable from './StatsTable';
//MonthSelection for BarChart
import FormSelectMonthForBarChart from './FormSelectMonthForBarChart';


//Date picker
import dayjs, { Dayjs } from 'dayjs';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

//Bar Chart
import BarChart from './BarChart';

export default function StatsSMS(props) {
    const styleOfColumns = "sms"
    const [configObjectChartBar, setConfigObjectChartBar] = React.useState({})
    
    const [startDateCharBar, setStartDateCharBar] = React.useState('2023-03-01');
    const [endDateCharBar, setEndDateCharBar] = React.useState('2023-03-31');
   
    const [startDate, setStartDate] = React.useState('2023-03-01');
    const [endDate, setEndDate] = React.useState('2023-03-31');
    
    // const [startDateCharBar, setStartDateCharBar] = React.useState('2023-02-01');
    // const [endDateCharBar, setEndDateCharBar] = React.useState('2023-02-28');
   
    // const [startDate, setStartDate] = React.useState('2023-02-01');
    // const [endDate, setEndDate] = React.useState('2023-02-28');
    
    
    const start = "2023-03-01"
    const end = "2023-03-31"
    
    // const start = "2023-02-01"
    // const end = "2023-02-28"
    
    let displayDataSmsChartBar = {"Message": {
        "lvn-stats-sms-list":[null] }}
    
    const { data, error, isSuccess, isLoading,  isError, refetch }= useQuery(['statsSMS'], () =>  {
        return statsSms(startDate, endDate)
    })
    
    
    
    let displayDataSMS = []
    if (isSuccess) {
            displayDataSMS = data["Message"]["lvn-stats-sms-list"]
        }
    
    
    // Bar Chart:
    
    
    function hadleMonthSelectionBarChart (event) {
        setConfigObjectChartBar (() => ({...event}))
   
    }

    React.useEffect ( () => {setStartDateCharBar ((prev) => configObjectChartBar.statsFirstDay); 
    setEndDateCharBar ((prev) => configObjectChartBar.statsLastDay);
     setTimeout ( () => refetchCharBar(), 800)  }, [configObjectChartBar])
    


     
   

     const { data: dataCharBar, error: errorCharBar, isSuccess: isSuccessCharBar, isLoading: isLoadingCharBar,  isError: isErrorCharBar, refetch: refetchCharBar }= useQuery(['statsSmsCharBar'], () =>  {
        return statsSms(startDateCharBar, endDateCharBar)
    })
    
    
    
    if (isSuccessCharBar) {
        displayDataSmsChartBar = dataCharBar["Message"]["lvn-stats-sms-list"]
        }
    
        
 
    return (
        <div className='main stats'>
            <h1>{props.contentLanguage.statisticsSMS}</h1> 
            <h3 className='stats-content'>{props.contentLanguage.statsDescriptionBarChart}</h3>
            <FormSelectMonthForBarChart 
                handleSelection = {hadleMonthSelectionBarChart}
            />
            <div className='stats-barChart'>
                {isSuccessCharBar && <BarChart  
                        dataForCharBar = {displayDataSmsChartBar}
                        statsStartDate = {startDateCharBar}
                        statsEndDate = {endDateCharBar}
                        />} 
                {isLoadingCharBar && <p className='apiReturnMessage' style={{marginTop:'20px'}}>{props.contentLanguage.apiDataLoading}</p>}
                {isErrorCharBar && <p className='apiReturnMessage' style={{marginTop:'20px'}}>{props.contentLanguage.apiDataError}{errorCharBar.response !== undefined ? errorCharBar.response.data["Message"] : error["message"]}</p>}
            </div>
           
            <h3 className='stats-content'>{props.contentLanguage.statsDescriptionTable}</h3>
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                
                <div className='stats-options-picker'>
                    <DesktopDatePicker
                        label={props.contentLanguage.startDate}
                        inputFormat="YYYY/MM/DD"
                        value={startDate}
                        minDate={dayjs('2017-01-01')}
                        onChange={(newValue) => {
                            setStartDate(newValue.format("YYYY-MM-DD"))
                        }}
                        renderInput={(params) => <TextField  size="small"  {...params} sx={{width: '145px', marginRight:"10px"}} />}
                        />
                    <DesktopDatePicker
                        label={props.contentLanguage.endDate}
                        inputFormat="YYYY/MM/DD"
                        value={endDate}
                        minDate={dayjs('2017-01-01')}
                        onChange={(newValue) => {
                            setEndDate(newValue.format("YYYY-MM-DD"));
                        }}
                        renderInput={(params) => <TextField  size="small"  {...params} sx={{width: '145px', marginRight:"10px"}} />}
                        />
                        <button className='stats-load-button' onClick={() => refetch()}>{props.contentLanguage.loadStats}</button>
                </div>
            </LocalizationProvider>
            {isSuccess && <StatsTable 
             dataToDisplay = {displayDataSMS}
             styleOfColumns = {styleOfColumns}
             contentLanguage = {props.contentLanguage}
             />} 
            {isLoading && <p className='apiReturnMessage'>{props.contentLanguage.apiDataLoading}</p>}
            {isError && <p className='apiReturnMessage'>{props.contentLanguage.apiDataError}{error.response !== undefined ? error.response.data["Message"] : error["message"]}</p>}
            </div>
    )
}