import React from "react";

export default function Sidebar(props) {

    const notActiveStyleClass = "sidebar--li"
    const activeStyleClass = "sidebar--li--active"

    return (
        <div className="sidebar">
            <ul className="sidebar--ul">
                <li id="ChooseVirNumber"  className={props.isActive.ChooseVirNumber?activeStyleClass:notActiveStyleClass} onClick={()=>props.handleNavClick("ChooseVirNumber")}>{props.contentLanguage.chooseVirtualNumberSidebar}</li>
                <li id="ManageNumbers"  className={props.isActive.ManageNumbers?activeStyleClass:notActiveStyleClass} onClick={()=>props.handleNavClick("ManageNumbers")}>{props.contentLanguage.manageNumbersSidebar}</li>
                <li id="StatsVoice"  className={props.isActive.StatsVoice?activeStyleClass:notActiveStyleClass} onClick={()=>props.handleNavClick("StatsVoice")}>{props.contentLanguage.statisticsSidebarVoice}</li>
                <li id="StatsSMS"  className={props.isActive.StatsSMS?activeStyleClass:notActiveStyleClass} onClick={()=>props.handleNavClick("StatsSMS")}>{props.contentLanguage.statisticsSidebarSMS}</li>
                <li id="Documentation"  className={props.isActive.Documentation?activeStyleClass:notActiveStyleClass} onClick={()=>props.handleNavClick("Documentation")}>{props.contentLanguage.docsSidebar}</li>
                <li id="NEXT2FA"  className={notActiveStyleClass} >NEXT 2FA</li>
                <li id="KYC"  className={notActiveStyleClass} >KYC</li>
                <li id="SIMSWAP"  className={notActiveStyleClass} >SIM SWAP</li>
                <li id="NUMBERVERIFY"  className={notActiveStyleClass} >NUMBER VERIFY</li>
            </ul>
        </div>
    )
}
