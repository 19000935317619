import axios from "axios"

const vndApi = axios.create({
    baseURL: "http://34.118.11.203"
})

const authApi = {
    "username":"gui",
    "password":"6u1!cCe5=eb"
    }


export const statsVoice  = async (start, end) => {
    const response = await vndApi.post("/lvn_stats_voice", JSON.stringify({
        ...authApi,
        "date_start": `${start} 00:00:00`,
        "date_end": `${end} 23:59:59`,
       }), {
        "headers":{
            "Content-Type": "application/json"
        }
    })
    console.log("Voice Data fetched")
    return response.data
}

export const statsSms  = async (start, end) => {
    const response = await vndApi.post("/lvn_stats_sms", JSON.stringify({
        ...authApi, 
        "date_start": `${start} 00:00:00`,
        "date_end": `${end} 23:59:59`,
    }), {
        "headers":{
            "Content-Type": "application/json"
        }
    })
    console.log("SMS Data fetched")
    return response.data
}

export const listAllBindings = async () => {
    const response = await vndApi.post("/lvn_list", JSON.stringify(authApi), {
        "headers":{
            "Content-Type": "application/json"
        }
    })
    return response.data
}


export const apiStatus = async () => {
    const response = await vndApi.post("/status", JSON.stringify(authApi), {
        "headers":{
            "Content-Type": "application/json"
        }
    })
    return response.data
}

export const deleteBinding  = async ({virt_num, real_num}) => {
    const response = await vndApi.post("/lvn_del", JSON.stringify({...authApi, "real_num": real_num,
    "virt_num": virt_num }), {
        "headers":{
            "Content-Type": "application/json"
        }
    })
    return response.data
}


export const insertBinding  = async ({virt_num, real_num}) => {
    const response = await vndApi.post("/lvn_ins", JSON.stringify({...authApi, "real_num": real_num,
    "virt_num": virt_num }), {
        "headers":{
            "Content-Type": "application/json"
        }
    })
    return response.data
}

export const searchBinding  = async (number) => {
    const response = await vndApi.post("/lvn_search", JSON.stringify({...authApi, "number": number}), {
        "headers":{
            "Content-Type": "application/json"
        }
    })
    return response.data
}


export default vndApi 