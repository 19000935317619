import React from "react";

export default function Modal({modalIsOpened, onClose, children }) {

    if (!modalIsOpened) {
        return null
    } else   
        return (
            <div className="modal-overlay">
                <div className="modal-container">
                    {children}
                </div>
            </div>
    )
}