import React from 'react';
import Modal from './Modal';
import { deleteBinding, insertBinding, listAllBindings} from '../api/vndApi';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export default function ManageNumbers (props) {
    

    const [modalIsOpened, setToggleModal] = React.useState(false)
    const [managedVirtualNumber, setManagedVirtualNumber] = React.useState("")
    const numbersToDisplay =  props.availableVirtualNumbersData.filter (item => props.chosenNumbers2.includes(item.virtualNumber) === true || item.bindedRealNumber.length > 0)
    
    // API:
    const queryClient = useQueryClient()

    // const {
    //     isLoading,
    //     isError,
    //     error,
    //     data: numbersdata
    // } = useQuery(['numbers'], ()=>listAllBindings())  

    const insertBindingMutation = useMutation(insertBinding, {
        onSuccess: () => {
            //Invalidates cache and refetch
            queryClient.invalidateQueries("numbers")
        },
        // onError: (error) => {
        //     console.log("Error during insertBindingMutation", error.response.data["Message"])
        // }
    } )

    const deleteBindingMutation = useMutation(deleteBinding, {
        onSuccess: () => {
            //Invalidates cache and refetch
            queryClient.invalidateQueries("numbers")
        },
        // onError: (error) => {
        //     console.log("Error during deleteBindingMutation", error.response.data["Message"])
        // }
    } )

 
    
    const displayVirtualNumbersToConfigure = numbersToDisplay.filter (item => props.chosenNumbers2.includes(item.virtualNumber)).map ( item => <React.Fragment key={item.virtualNumber}>
        <div className="main-available-single-number">{displayFormattedNumber(item.virtualNumber)}</div><button className='button-default button-to-configure' onClick={() => {setToggleModal(true); setManagedVirtualNumber (item); props.handleClickManage(item.virtualNumber)}}>{props.contentLanguage.configure}</button>
    </React.Fragment>)

    const displayActiveVirtual = numbersToDisplay.filter(item => item.bindedRealNumber.length>0).map ( item => <React.Fragment key={item.virtualNumber}>
        <div className="main-available-single-number">{displayFormattedNumber(item.virtualNumber)}</div><button className='choose-vir-number-button' onClick={() => {setToggleModal(true); setManagedVirtualNumber (item); props.handleClickManage(item.virtualNumber)}}>{props.contentLanguage.manageNumber}</button>
    </React.Fragment>)
 
 
    const [maskedNumber, setMaskedNumber] = React.useState("")
    const [phoneInputValue, setPhoneInputValue] = React.useState("")
    
    function handleInput(event) {
        const formattedPhoneNumber = formatPhoneNumber(event.target.value)
        setPhoneInputValue(formattedPhoneNumber)
    }
    

    function formatPhoneNumber(value) {
        if (!value) return value
        const phoneNumber = value.replace(/[^\d]/g,"")
        const phoneNumberLength = phoneNumber.length
        if (phoneNumberLength < 4) return phoneNumber
        if (phoneNumberLength <7 ) {
            return `${phoneNumber.slice(0,3)} ${phoneNumber.slice(3)}`
        }
        return `${phoneNumber.slice(0,3)} ${phoneNumber.slice(3,6)} ${phoneNumber.slice(6,9)}`
    }

    function displayFormattedNumber (number) {
        return `+${number.slice(0,2)} ${number.slice(2,5)} ${number.slice(5,8)} ${number.slice(8,11)}`
    }

    // new handleSubmit
    function handleSubmit(event) {
        event.preventDefault()
        if (checkNumberLength (phoneInputValue) === true && phoneInputValue.length>1) {
            const finalMaskedNumber = `48${phoneInputValue.replaceAll(' ','')}`
                if (managedVirtualNumber.bindedRealNumber.length === 0) {
                    insertBindingMutation.mutate({virt_num: managedVirtualNumber.virtualNumber, real_num: finalMaskedNumber}, {
                        onSuccess: () => {setManagedVirtualNumber((oldNumber)=> ({...oldNumber, bindedRealNumber:finalMaskedNumber})); props.handleChooseNumClick2(managedVirtualNumber.virtualNumber)},
                        onError: () => console.log("insertBinding Error")
                    })}
                 else if (managedVirtualNumber.bindedRealNumber.length > 0) {
                    console.log("UPDATE")
                    deleteBindingMutation.mutate({virt_num: managedVirtualNumber.virtualNumber, real_num: managedVirtualNumber.bindedRealNumber}, {
                        onError: ()=> console.log("deleteBinding Error"),
                        onSuccess: ()=> setTimeout ( () => insertBindingMutation.mutate({virt_num: managedVirtualNumber.virtualNumber, real_num: finalMaskedNumber},{
                            onSuccess: ()=> {setManagedVirtualNumber((oldNumber)=> ({...oldNumber, bindedRealNumber:finalMaskedNumber})); console.log("success")},
                            onError: () => console.log("insertBinding Error")
                        }), 1200),
                    })                  
                }
            setMaskedNumber("")
            setPhoneInputValue("")
        }             
    }
        

    
    function deleteMasking(virtnum, realnum) {
        deleteBindingMutation.mutate({virt_num:virtnum, real_num:realnum}, {
            onSuccess: () => {
                props.handleChooseNumClick2(virtnum)
                setMaskedNumber("")
                setManagedVirtualNumber((oldNumber)=> ({...oldNumber, bindedRealNumber:maskedNumber}))

        },
            onError: () => {
            console.log("deleteBinding error")
            }
        })
    }
    
    
    
    const displayManagedNumberCurrentBinding = <p>{managedVirtualNumber && managedVirtualNumber.bindedRealNumber.length>0 ? `${props.contentLanguage.maskingActiveFor} ${displayFormattedNumber(managedVirtualNumber.bindedRealNumber)}`: props.contentLanguage.currentlyNoMasking}</p>

    function checkNumberLength (number) {
        if (number.length >0 && number.length<11) {
            return false
        } else {
            return true
        }
    }

    function displayApiMutationError (clear) {
     
        if(deleteBindingMutation.isError || insertBindingMutation.isError) {
            
            if (deleteBindingMutation.isError) {
                return <p className='modal-api-error'>{props.contentLanguage.apiDataError}{deleteBindingMutation.error.response !== undefined ? deleteBindingMutation.error.response.data["Message"] : deleteBindingMutation.error["message"]} {props.contentLanguage.contactAdministrator}</p>
                
            } else if (insertBindingMutation.isError) {
                 return <p className='modal-api-error'>{props.contentLanguage.apiDataError}{insertBindingMutation.error.response !== undefined ? insertBindingMutation.error.response.data["Message"]: insertBindingMutation.error["message"]} {props.contentLanguage.contactAdministrator}</p>
            
            } else {
                 return <p>&nbsp;</p>
        }
        
    }
    }

    return (
        <div className='manage-numbers main'>
            {/* <button onClick={() => console.log(deleteBindingMutation.isSuccess)}>isSuccess</button>
            <button onClick={() => console.log(deleteBindingMutation.status)}>status</button>
            <button onClick={() => console.log(deleteBindingMutation.error.response.data)}>log error</button>  */}
            <h1>{props.contentLanguage.yourVirtualNumbersToConfigure}</h1>
                {props.chosenNumbers2.length === 0 ? <p>{props.contentLanguage.virtualNumberNotSelected}</p> : null }
            <div className="main-available-number-display">
                {displayVirtualNumbersToConfigure}
            </div>
            
            <h1>{props.contentLanguage.yourActiveVirtualNumbers}</h1>
                {displayActiveVirtual.length === 0 ? <p>{props.contentLanguage.noActiveVirtualNumbers}</p> : null }
            <div className="main-available-number-display">
                {displayActiveVirtual.length === 0 ? null : displayActiveVirtual }
            </div>

            <Modal 
                modalIsOpened = {modalIsOpened}
                handleClose = {() => setToggleModal(false)}
                >
                    <div className='manage-number-modal'>
                        <form onSubmit={handleSubmit}>
                            <h2>{props.contentLanguage.virtualNumberConfiguration}</h2>
                            <p>{managedVirtualNumber !== "" ? displayFormattedNumber(managedVirtualNumber.virtualNumber) : null}</p>
                            {displayManagedNumberCurrentBinding}
                            <h3>{props.contentLanguage.enterNumberForMasking}</h3>
                            <div className='modal-input-field-and-length-checker'>
                                <div className="modal-container">
                                    <input
                                    type="text"
                                    name="masked-number"
                                    value={phoneInputValue}
                                    placeholder={`${props.contentLanguage.inputFormat}123 456 789`}
                                    onChange={handleInput}
                                    />
                                    
                                    <button type="submit" className={phoneInputValue.length<11 ? 'modal-button-inactive button-default' : 'modal-button button-default'}>{props.contentLanguage.turnOnMasking}</button>

                                    {/* <button type="submit" className='modal-button button-default'>{props.contentLanguage.turnOnMasking}</button> */}
                                    
                                    {!managedVirtualNumber.bindedRealNumber ? <button type="button" onClick={() =>{props.handleChooseNumClick2(managedVirtualNumber.virtualNumber); setToggleModal(false); setPhoneInputValue("")}} className='delete-masking button-default'>{props.contentLanguage.cancelNumberChoice}</button> : null}


                                    {managedVirtualNumber.bindedRealNumber ? <button type="button" onClick={() => deleteMasking(managedVirtualNumber.virtualNumber, managedVirtualNumber.bindedRealNumber)} className='delete-masking button-default'>{props.contentLanguage.turnOffMasking}</button> : null}
                                </div>
                                
                                <div className='modal-length-check-display'>
                                    {!checkNumberLength(phoneInputValue) ? <p>{props.contentLanguage.numberTooShort}</p> : <p>&nbsp;</p>}
                                </div>
                                {displayApiMutationError()}
                            </div>
                        </form>
                        <div className='modal-closure'>
                            <button className='modal-button button-default' onClick={() => {setToggleModal(false); setPhoneInputValue("")}}>{props.contentLanguage.close}</button>
                        </div>
                    </div>    
                </Modal>
            

        </div>
    )

}