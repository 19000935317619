import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import dayjs from 'dayjs';



export default function FormSelectMonthForBarChart(props) {
 
//English months
const months = ["January", "February", "March", "April", "May", "June", "July",
"August", "September", "October", "November", "December"]

//starting point for stats display:
const statsStartDate = "2022-10-01" 
const statsEndDate = dayjs().endOf('month')
const statsOptionsCalendar =[]

for (let i=0; i<Math.ceil(statsEndDate.diff(statsStartDate, 'month', true)); i++) {
let tempPointInTime= dayjs(statsStartDate).add(i,'month')
statsOptionsCalendar.push ({
    year: tempPointInTime.year(),
    month: tempPointInTime.month(),
    statsFirstDay:  dayjs(`${tempPointInTime.year()}-${tempPointInTime.month()+1}`).startOf('month').format('YYYY-MM-DD'),
    statsLastDay:  dayjs(`${tempPointInTime.year()}-${tempPointInTime.month()+1}`).endOf('month').format('YYYY-MM-DD'),
    monthName: months[tempPointInTime.month()],
    id : i
})
}

const displayOptions = statsOptionsCalendar.map((item) => {
   return <MenuItem key={item.id} value={item.id}>{`${item.year} ${item.monthName}`}</MenuItem>
})
  
const [idBarChartSelectedObject, setIdBarChart] = React.useState('5');

const handleChange = (event) => {
    setIdBarChart((prevState) => event.target.value);
  };
  
React.useEffect ( () => props.handleSelection(statsOptionsCalendar[idBarChartSelectedObject]), [idBarChartSelectedObject])
  
  return (
    <>
    <FormControl sx={{ mt: 2,  minWidth: 160, mb:"5px" }} size="small">
      <InputLabel id="demo-select-small">Select month</InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={idBarChartSelectedObject}
        label="Select month"
        onChange={handleChange}
        >
        {displayOptions}
      </Select>
    </FormControl>
    </>
  );
}