import React from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';



export default function StatsTable(props) {
    
  let finalTableData=[]


  if (props.styleOfColumns === "sms") {
    finalTableData = props.dataToDisplay
  } else if (props.styleOfColumns === "voice") {
    finalTableData = props.dataToDisplay.map (item => item['duration'] === 0 ? {...item, "status": props.contentLanguage.statusMissedCall } :  {...item, "status": props.contentLanguage.statusAnsweredCall })
  } 
  
    const columnsVoice = [
        { field: "id", headerName: "ID", width: 70},
        { field: "serial_cdr", headerName: "CDR"},
        // { field: "call_data", headerName: "DATE AND TIME", width: 235},
        { field: "call_data_f", headerName: "DATE AND TIME", width: 160},
        { field: "num_a", headerName: "A NUMBER", width: 110},
        { field: "num_b", headerName: "B NUMBER", width: 110},
        { field: "num_v", headerName: "VIRTUAL NUM", width: 115},
        { field: "country_a", headerName: "A COUNTRY"},
        { field: "country_b", headerName: "B COUNTRY"},
        { field: "network_a", headerName: "A NETWORK", width: 200},
        { field: "network_b", headerName: "B NETWORK", width: 200},
        { field: "status", headerName: "STATUS", width: 110},
        { field: "duration", headerName: "DURATION"},
        // { field: "cost", headerName: "COST"},
      ];

 const columnsSms = [
        { field: "id", headerName: "ID", width: 70},
        { field: "id_sms", headerName: "SMS ID", width: 70},
        { field: "call_data_f", headerName: "DATE AND TIME", width: 160},
        { field: "num_a", headerName: "A NUMBER", width: 110},
        { field: "num_b", headerName: "B NUMBER", width: 110},
        { field: "num_v", headerName: "VIRTUAL NUM", width: 115},
        { field: "country_a", headerName: "A COUNTRY"},
        { field: "country_b", headerName: "B COUNTRY"},
        { field: "network_a", headerName: "A NETWORK", width: 200},
        { field: "network_b", headerName: "B NETWORK", width: 200},
        // { field: "cost", headerName: "COST"},
      ];

 
    return (
      <>
      <div className="stats-table-container" style={{ height: '800px', width: '100%', marginBottom:'40px' }}>
        <DataGrid
            rows={finalTableData}
            columns={props.styleOfColumns === "sms" ? columnsSms : columnsVoice}
            components={{ Toolbar: GridToolbar }}
            disableSelectionOnClick={true}
            getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'Mui-even' : 'Mui-odd'
          }
          initialState={{
              sorting: {
                sortModel: [{ field: "call_data_f", sort: 'desc' }],
              },
            }}
            />
        </div> 
                  
      </>
    )
}