
const apiDataOffline = 
{"Message":
    {"lvn-bindings":12,
    "lvn-bindings-list":
        [
            {"real_num":"60111122233","virt_num":"42123123123"},
            {"real_num":"244324324324","virt_num":"42234234234"},
            {"real_num":"48697631626","virt_num":"48459050060"},
            // {"real_num":"48459561777","virt_num":"48459050061"},
            // {"real_num":"48570557769","virt_num":"48459050062"},
            // {"real_num":"48459561777","virt_num":"48459050063"},
            // {"real_num":"48500333843","virt_num":"48459050066"},
            // {"real_num":"48880856637","virt_num":"48459050069"},
            // {"real_num":"99912312313","virt_num":"55123123123"},
            // {"real_num":"88321123321","virt_num":"66124124124"},
            // {"real_num":"99123123123","virt_num":"88123123123"},
            {"real_num":"99912312312","virt_num":"88123123124"}],
    "status":"ok",
    "timestamp":"2023-01-13 11:01:37.720166"},
    "Success":true}

export default apiDataOffline;