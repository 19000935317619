import './App.css';
import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import ChooseVirNumber from './components/ChooseVirNumber';
import ManageNumbers from './components/ManageNumbers';
import StatsVoice from './components/StatsVoice';
import StatsSMS from './components/StatsSMS';
import Documentation from './components/Documentation';
import defaultVirtNums from './data/virtual_nums';
import apiDataOffline from './data/api_data_backUp';
import Translation from './data/Translation.json'
import { useQuery } from '@tanstack/react-query';
import { listAllBindings } from './api/vndApi';

function App() {
  let apiDataBackUp = apiDataOffline

  // Set display language:
  const [selectedLanguage, setLanguage] = React.useState(localStorage.getItem('selectedLanguage') ? JSON.parse(localStorage.getItem('selectedLanguage')) :"Polish")
  const [content, setContent] = React.useState({})
  
  React.useEffect (() => {
    if (selectedLanguage === "English") {
      setContent(Translation.English)
    } else if (selectedLanguage === "Polish") {
      setContent(Translation.Polish)
    }
  }, [selectedLanguage])
  
  function selectLanguage (language) {
    setLanguage(language)
  }
  React.useEffect (() => localStorage.setItem('selectedLanguage', JSON.stringify(selectedLanguage)), [selectedLanguage])
  
  // Control the nav in Sidebar
  const [activeSidebarItem, setActiveSidebarItem] = React.useState(localStorage.getItem('activeSidebarItem') ? JSON.parse(localStorage.getItem('activeSidebarItem')) :
  {
    ChooseVirNumber: true,
    ManageNumbers: false,
    StatsVoice: false,
    StatsSMS: false,
    Documentation: false,
  }
  )
  
  function setNav (navId) {
    setActiveSidebarItem ({
      ChooseVirNumber: false,
      ManageNumbers: false,
      StatsVoice: false,
      StatsSMS: false,
      Documentation: false,
      [navId] : true
    })
  }
  
  React.useEffect (() => localStorage.setItem('activeSidebarItem', JSON.stringify(activeSidebarItem)), [activeSidebarItem])
  
  const [availableVirtualNumbers, setAvailableVirtualNumbers] = React.useState([])
  // Get data from API  
  const { data, error, isError, isLoading, isSuccess } = useQuery(['numbers'], ()=>listAllBindings())  
  
  

  

  if (isLoading) {
    // console.log("Data is loading...")
  }
  if (isError) {
    // console.log("API ERROR - ", error.response.data["Message"])
  }
  if (isSuccess) {
    // console.log("Data is loaded")
    apiDataBackUp = data
  }
  
  React.useEffect(() => {setAvailableVirtualNumbers(createAvailableVirtualNumbers(apiDataBackUp))}, [apiDataBackUp])
          
  // Control the apiData and available numbers display
  function createAvailableVirtualNumbers (apiData) {
    let currentNumber
    const tempAvailableVirtualNumbers =[]
    for (let i=0; i<defaultVirtNums.length;i++) {
      currentNumber=defaultVirtNums[i].number
      
      if (apiData["Message"]["lvn-bindings-list"].filter(x => x["virt_num"] === currentNumber).length===1) {
        tempAvailableVirtualNumbers.push({
          virtualNumber: currentNumber,
          binded: true,
          bindedRealNumber: apiData["Message"]["lvn-bindings-list"].filter(x => x["virt_num"] === currentNumber)[0]["real_num"],
          chosen: false,
          available: false,
          maskedNumber: "",
          managed:false,
        }
        )
      } else {
        tempAvailableVirtualNumbers.push({
          virtualNumber: currentNumber,
          binded: false,
          bindedRealNumber: "",
          chosen: false,
          available: true,
          maskedNumber: "",
          managed:false,
        }
        )
        }
      }
    return tempAvailableVirtualNumbers
  }

  //new Virtual number selection

  const [numbersAreChosen, setNumbersAreChosen] = React.useState([])

  function numberIsChosen (virtualNumber) {
    setNumbersAreChosen( (prevNumbersAreChosen) => {
      if (numbersAreChosen.includes(virtualNumber)) {
        return prevNumbersAreChosen.filter (item => item !== virtualNumber)
      } else if (!numbersAreChosen.includes(virtualNumber)) {
        return [...prevNumbersAreChosen, virtualNumber]
      }
    }
    )
  }

  function toggleNumberStateManage (virtNumber) {
    const tempManagedNumber = []
    availableVirtualNumbers.map( item => item.virtualNumber === virtNumber ? tempManagedNumber.push({...item, managed: !item.managed}) : tempManagedNumber.push(item))
    setAvailableVirtualNumbers (()=> tempManagedNumber)
  }

  function updateVirNumberDB (updatedVirtualNumberDB) {
    setAvailableVirtualNumbers ((oldVirtualNumbers) => updatedVirtualNumberDB)
  }


 

  return (
    <div className="App">
      <Header 
        handleLanguageSelectionClick = {selectLanguage}
        selectedLanguage = {selectedLanguage}
        />
      <div className='main-content'>
        <Sidebar 
          handleNavClick = {setNav}
          isActive = {activeSidebarItem}
          contentLanguage = {content}
          />
        <div className='main-content-main'>
        {isLoading ? <h5 className='apiReturnMessage'>{content.apiDataLoading}</h5> : null}
        {isError ? <h5 className='apiReturnMessage'>{content.apiDataError}{error.response !== undefined ? error.response.data["Message"] : null} {content.contactAdministrator}</h5> : null}
       {isError ? console.log('axios message', error["message"]) : null}
        {isSuccess ? <>
          {/* <ApiComms /> */}
          {activeSidebarItem.ChooseVirNumber ? <ChooseVirNumber 
            availableVirtualNumbersData = {availableVirtualNumbers}
            handleChooseNumClick2 = {numberIsChosen}
            chosenNumbers2 = {numbersAreChosen}
            contentLanguage = {content}
            
            /> : null}
          {activeSidebarItem.ManageNumbers ? <ManageNumbers 
            availableVirtualNumbersData = {availableVirtualNumbers}
            handleClickManage ={toggleNumberStateManage}
            updateVirNumberDB = {updateVirNumberDB}
            contentLanguage = {content}
            handleChooseNumClick2 = {numberIsChosen}
            chosenNumbers2 = {numbersAreChosen}
            /> : null}
          {activeSidebarItem.StatsVoice ? <StatsVoice 
            contentLanguage = {content}
            /> : null}
          {activeSidebarItem.StatsSMS ? <StatsSMS 
            contentLanguage = {content}
            /> : null}
          {activeSidebarItem.Documentation ? <Documentation 
            contentLanguage = {content}
            /> : null}
        </> : null}
        </div>
      </div>
      <Footer 
      contentLanguage = {content}
      />
      
    </div>
  );
}

export default App;
