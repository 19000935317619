const availableNums = 
    [
        {
            number: "48459050060"
        },
        {
            number: "48459050061"
        },
        {
            number: "48459050062"
        },
        {
            number: "48459050063"
        },
        {
            number: "48459050064"
        },
        {
            number: "48459050065"
        },
        {
            number: "48459050066"
        },
        {
            number: "48459050067"
        },
        {
            number: "48459050068"
        },
        {
            number: "48459050069"
        },    
    ]

export default availableNums;