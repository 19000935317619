import React from 'react';



export default function ChooseVirNumber (props) {

    const styleChooseNumberButtonNotAvailable = "choose-vir-number-button-notavailable"
    const styleChooseNumberButtonAvailable = "choose-vir-number-button"
    const availableVirtualNumbers = props.availableVirtualNumbersData.filter (item => item.bindedRealNumber.length === 0)
    const bindedRealNumbers = props.availableVirtualNumbersData.filter (item => item.bindedRealNumber.length>0)


    const displayAvailableNumbers = availableVirtualNumbers.map (item => <React.Fragment key={item.virtualNumber}>
        <div className="main-available-single-number">{displayFormattedNumber(item.virtualNumber)}</div>
        <button className={props.chosenNumbers2.includes(item.virtualNumber) ? styleChooseNumberButtonNotAvailable : styleChooseNumberButtonAvailable} onClick={() => {props.handleChooseNumClick2(item.virtualNumber)}}>{props.chosenNumbers2.includes(item.virtualNumber) ? props.contentLanguage.selectedNumber : props.contentLanguage.selectNumber}</button>
        </React.Fragment>)
    
    const displayActiveMaskingNumbers = bindedRealNumbers.map(item => <React.Fragment key={item.virtualNumber}>
            <div className="main-available-single-number">{displayFormattedNumber(item.virtualNumber)}</div>
            <button className="choose-vir-number-button-active-masked">{props.contentLanguage.numberActivelyMasking}</button>
            </React.Fragment>)

    function displayFormattedNumber (number) {
        return `+${number.slice(0,2)} ${number.slice(2,5)} ${number.slice(5,8)} ${number.slice(8,11)}`
    }

    return (
        <div className='choose-vir-number main'>
            <h2>{props.contentLanguage.availableVirtualNumbers}</h2>
            <div className="main-available-number-display">
                {displayAvailableNumbers}
            </div>
            <h2 style={{marginTop:20}}>{props.contentLanguage.numbersActivelyUsedForMasking}</h2>
            <div className="main-available-number-display">
                {displayActiveMaskingNumbers}
            </div>      
        </div>
    )

}