import React from 'react';

export default function Documentation(props) {

    return (
        <div className='main documentaion'>
            <h1>{props.contentLanguage.documentation}</h1>
            <div className='docu-tech'>
  
                <p>
                    <strong>Technical Description</strong>
                </p>
                <p>ver. 2023-01-20</p>
                <p>This document describes details of Virtual Numbers RESTAPI Deployment.</p>
                <h1 id="table-of-contents.">Table of contents:</h1>
                <p>
                    <a href="#api-request-availability-check.">
                    API request: availability check.
                    </a>
                </p>
                <p>
                    <a href="#api-request-service-status.">API request: service status.</a>
                </p>
                <p>
                    <a href="#api-list-bindings-request.">API list bindings request.</a>
                </p>
                <p>
                    <a href="#api-request-search-number-or-pattern-in-bindings-table.">
                    API request: search number or pattern in bindings table.
                    </a>
                </p>
                <p>
                    <a href="#api-request-insert-new-binding-into-table.">
                    API request: insert new binding into table.
                    </a>
                </p>
                <p>
                    <a href="#api-request-delete-binding-from-table.">
                    API request: delete binding from table.
                    </a>
                </p>
                <p>
                    <a href="#api-request-get-voice-calls-statistics-data.">
                    API request: get voice calls statistics data.
                    </a>
                </p>
                <p>
                    <a href="#api-request-get-sms-statistics-data.">
                    API request: get SMS statistics data.
                    </a>
                </p>
                <p>
                    <a href="#error-response-list.">Error response list.</a>
                </p>
                <p>
                    <strong>
                    <br />
                    </strong>
                </p>
                <p>Provided API service is based on RESTfull solution.</p>
                <p>
                    IP address, TCP port and authorization credentials are provided in separate
                    tech specification individually for each user. Addresses used in this
                    document are example.
                </p>
                <p>Available REST requests are explained in the following sections.</p>
                <h1 id="api-request-availability-check.">API request: availability check.</h1>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Request:</td>
                        <td>GET http://127.0.0.1/ping</td>
                    </tr>
                    <tr className="even">
                        <td>Req. description:</td>
                        <td>Check API service availability.</td>
                    </tr>
                    <tr className="odd">
                        <td>cURL snippet:</td>
                        <td>curl --location --request GET 'http://127.0.0.1/ping'</td>
                    </tr>
                    <tr className="even">
                        <td>Response:</td>
                        <td>
                        STATUS 200 OK
                        <br />
                        {"{"}
                        <br />
                        "Message": "Pong!",
                        <br />
                        "Success": true
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>Resp. description:</td>
                        <td>Success: „True” means service is up, running and reachable.</td>
                    </tr>
                    </tbody>
                </table>
                <h1 id="api-request-service-status.">API request: service status.</h1>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Request:</td>
                        <td>POST http://127.0.0.1/status</td>
                    </tr>
                    <tr className="even">
                        <td>Req. description:</td>
                        <td>Returns API service status</td>
                    </tr>
                    <tr className="odd">
                        <td>cURL snippet:</td>
                        <td>
                        <p>curl --location --request POST 'http://127.0.0.1/status' \</p>
                        <p>--header 'Content-Type: application/json' \</p>
                        <p>--data-raw '{"{"}</p>
                        <p>"username": "&lt;user&gt;",</p>
                        <p>"password": "&lt;pass&gt;"</p>
                        <p>{"}"}'</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Body description:</td>
                        <td>
                        username: auth username
                        <br />
                        password: auth password
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        <p>
                            STATUS 200 OK
                            <br />
                            {"{"}
                        </p>
                        <p>"Message": {"{"}</p>
                        <p>"lvn-bindings": "9",</p>
                        <p>"status": "ok",</p>
                        <p>"timestamp": "2022-11-07 19:14:44.712420"</p>
                        <p>{"}"},</p>
                        <p>"Success": true</p>
                        <p>{"}"}</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Resp. description:</td>
                        <td>
                        <p>
                            lvn-bindings: number of rows in real number to virtual number DB
                        </p>
                        <p>status: ok = service is up and running</p>
                        <p>timestamp: timestamp of response</p>
                        <p>success: true = response returned OK</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h1 id="api-list-bindings-request.">
                    <strong>API list bindings request.</strong>
                </h1>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Request:</td>
                        <td>POST http://127.0.0.1/lvn_list</td>
                    </tr>
                    <tr className="even">
                        <td>Req. description:</td>
                        <td>Lists bindings table real_number - virtual_number</td>
                    </tr>
                    <tr className="odd">
                        <td>cURL snippet:</td>
                        <td>
                        <p>curl --location --request POST 'http://127.0.0.1/lvn_list' \</p>
                        <p>--header 'Content-Type: application/json' \</p>
                        <p>--data-raw '{"{"}</p>
                        <p>"username": "&lt;user&gt;",</p>
                        <p>"password": "&lt;pass&gt;"</p>
                        <p>{"}"}''</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Body description:</td>
                        <td>
                        username: auth username
                        <br />
                        password: auth password
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        <p>
                            STATUS: 200 OK
                            <br />
                            {"{"}
                        </p>
                        <p>"Message": {"{"}</p>
                        <p>"lvn-bindings": 2,</p>
                        <p>"lvn-bindings-list": [</p>
                        <p>{"{"}</p>
                        <p>"real_num": "60111122233",</p>
                        <p>"virt_num": "42123123123"</p>
                        <p>{"}"},</p>
                        <p>{"{"}</p>
                        <p>"real_num": "244324324324",</p>
                        <p>"virt_num": "42234234234"</p>
                        <p>{"}"}</p>
                        <p>],</p>
                        <p>"status": "ok",</p>
                        <p>"timestamp": "2022-11-07 19:18:53.904109"</p>
                        <p>{"}"},</p>
                        <p>"Success": true</p>
                        <p>{"}"}</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Resp. description:</td>
                        <td>
                        <p>
                            lvn-bindings: number of rows in real number to virtual number DB
                        </p>
                        <p>
                            lvn-bindings-list: list of real number to virtual number bindings
                        </p>
                        <p>status: ok = service is up and running</p>
                        <p>timestamp: timestamp of response</p>
                        <p>success: true = response returned OK</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h1 id="api-request-search-number-or-pattern-in-bindings-table.">
                    <strong>API request: search number or pattern in bindings table.</strong>{" "}
                </h1>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Request:</td>
                        <td>POST http://127.0.0.1/lvn_search</td>
                    </tr>
                    <tr className="even">
                        <td>Req. description:</td>
                        <td>Search given number or pattern in bindings table</td>
                    </tr>
                    <tr className="odd">
                        <td>cURL snippet:</td>
                        <td>
                        <p>curl --location --request POST 'http://127.0.0.1/lvn_search' \</p>
                        <p>--header 'Content-Type: application/json' \</p>
                        <p>--data-raw '{"{"}</p>
                        <p>"number": "48123123123",</p>
                        <p>"username": "&lt;user&gt;",</p>
                        <p>"password": "&lt;pass&gt;"</p>
                        <p>{"}"}'</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Body description:</td>
                        <td>
                        <p>
                            number: number or SQL like % pattern you are looking for in bindings
                            DB
                            <br />
                            48123123123 – number
                            <br />
                            % - wildcard: any string of digits, eg:
                            <br />
                            123% - any number starting with 123
                            <br />
                            %321 – any number ending with 321
                        </p>
                        <p>
                            username: auth username
                            <br />
                            password: auth password
                        </p>
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        <p>STATUS 200 OK</p>
                        <p>{"{"}</p>
                        <p>"Message": {"{"}</p>
                        <p>"lvn-bindings-found": 1,</p>
                        <p>"lvn-bindings-found-list": [</p>
                        <p>{"{"}</p>
                        <p>"real_num": "48123123123",</p>
                        <p>"virt_num": "48459050060"</p>
                        <p>{"}"}</p>
                        <p>],</p>
                        <p>"status": "ok",</p>
                        <p>"timestamp": "2022-11-07 19:38:59.092204"</p>
                        <p>{"}"},</p>
                        <p>"Success": true</p>
                        <p>{"}"}</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Resp. description:</td>
                        <td>
                        <p>lvn-bindings-found: number of bindings found</p>
                        <p>
                            lvn-bindings-found-list: real number to virtual number binding found
                            for searched number
                        </p>
                        <p>status: ok = service is up and running</p>
                        <p>timestamp: timestamp of response</p>
                        <p>success: true = response returned OK</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h1 id="api-request-insert-new-binding-into-table.">
                    <strong>API request: insert new binding into table.</strong>{" "}
                </h1>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Request:</td>
                        <td>POST http://127.0.0.1/lvn_ins</td>
                    </tr>
                    <tr className="even">
                        <td>Req. description:</td>
                        <td>Insert new binding into table</td>
                    </tr>
                    <tr className="odd">
                        <td>cURL snippet:</td>
                        <td>
                        <p>curl --location --request POST 'http://127.0.0.1/lvn_ins' \</p>
                        <p>--header 'Content-Type: application/json' \</p>
                        <p>--data-raw '{"{"}</p>
                        <p>"virt_num": "48321321321",</p>
                        <p>"real_num": "48999123321",</p>
                        <p>"username": "&lt;user&gt;",</p>
                        <p>"password": "&lt;pass&gt;"</p>
                        <p>{"}"}'</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Body description:</td>
                        <td>
                        <p>virt_num: virtual number</p>
                        <p>real_num: real number</p>
                        <p>
                            username: auth username
                            <br />
                            password: auth password
                        </p>
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        <p>STATUS 200 OK</p>
                        <p>{"{"}</p>
                        <p>"Message": {"{"}</p>
                        <p>"lvn-bindings-inserted": {"{"}</p>
                        <p>"real_num": "48999123321",</p>
                        <p>"virt_num": "48321321321"</p>
                        <p>{"}"},</p>
                        <p>"status": "insert ok",</p>
                        <p>"timestamp": "2022-11-07 19:58:27.535548"</p>
                        <p>{"}"},</p>
                        <p>"Success": true</p>
                        <p>{"}"}</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Resp. description:</td>
                        <td>
                        <p>lvn-bindings-inserted: bindings inserted into table</p>
                        <p>
                            status: insert ok = service is up and running and insert commited
                            successfuly
                        </p>
                        <p>timestamp: timestamp of response</p>
                        <p>success: true = response returned OK</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h1 id="api-request-delete-binding-from-table.">
                    <strong>API request: delete binding from table.</strong>{" "}
                </h1>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Request:</td>
                        <td>POST http://127.0.0.1/lvn_del</td>
                    </tr>
                    <tr className="even">
                        <td>Req. description:</td>
                        <td>Delete binding from table</td>
                    </tr>
                    <tr className="odd">
                        <td>cURL snippet:</td>
                        <td>
                        <p>curl --location --request POST 'http://127.0.0.1/lvn_del' \</p>
                        <p>--header 'Content-Type: application/json' \</p>
                        <p>--data-raw '{"{"}</p>
                        <p>"virt_num": "48321321321",</p>
                        <p>"real_num": "48999123321",</p>
                        <p>"username": "&lt;user&gt;",</p>
                        <p>"password": "&lt;pass&gt;"</p>
                        <p>{"}"}'</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Body description:</td>
                        <td>
                        <p>virt_num: virtual number</p>
                        <p>real_num: real number</p>
                        <p>
                            username: auth username
                            <br />
                            password: auth password
                        </p>
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        <p>STATUS 200 OK</p>
                        <p>{"{"}</p>
                        <p>"Message": {"{"}</p>
                        <p>"lvn-bindings-deleted": {"{"}</p>
                        <p>"real_num": "48999123321",</p>
                        <p>"virt_num": "48321321321"</p>
                        <p>{"}"},</p>
                        <p>"status": "delete ok",</p>
                        <p>"timestamp": "2022-11-07 21:49:22.882743"</p>
                        <p>{"}"},</p>
                        <p>"Success": true</p>
                        <p>{"}"}</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Resp. description:</td>
                        <td>
                        <p>lvn-bindings-deleted: bindings inserted into table</p>
                        <p>
                            status: delee ok = service is up and running and delete commited
                            successfuly
                        </p>
                        <p>timestamp: timestamp of response</p>
                        <p>success: true = response returned OK</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h1 id="api-request-get-voice-calls-statistics-data.">
                    <strong>API request: get voice calls statistics data.</strong>{" "}
                </h1>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Request:</td>
                        <td>POST http://127.0.0.1/lvn_stats_voice</td>
                    </tr>
                    <tr className="even">
                        <td>Req. description:</td>
                        <td>
                        <p>
                            Get voice calls statistics data based on filter params:
                            <br />
                            - date_start - MANDATORY
                            <br />- date_end - MANDATORY
                        </p>
                        <p>- num_a (calling party) - OPTIONAL</p>
                        <p>
                            - num_v (virtual number – binded with num_b) - OPTIONAL
                            <br />- num_b (called party – binded with num_v) - OPTIONAL
                        </p>
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>cURL snippet:</td>
                        <td>
                        <p>
                            curl --location --request POST 'http://127.0.0.1/lvn_stats_voice' \
                        </p>
                        <p>--header 'Content-Type: application/json' \</p>
                        <p>--data-raw '{"{"}</p>
                        <p>"username": "&lt;use&gt;",</p>
                        <p>"password": "&lt;pass&gt;",</p>
                        <p>"date_start": "2022-01-16",</p>
                        <p>"date_end": "2023-01-19",</p>
                        <p>"num_a": "48697123456",</p>
                        <p>"num_b": "48697123456",</p>
                        <p>"num_v": "48459987654"</p>
                        <p>{"}"}'</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Body description:</td>
                        <td>
                        <p>
                            date_start: period starting datetime
                            <br />
                            date_end: period ending datetime
                        </p>
                        <p>num_a: calling party number - OPTIONAL</p>
                        <p>
                            num_v: virtual number – (binded with num_b) - OPTIONAL
                            <br />
                            num_b: called party number – (binded with num_v) – OPTIONAL
                        </p>
                        <p>
                            username: auth username
                            <br />
                            password: auth password
                        </p>
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        <p>STATUS 200 OK</p>
                        <p>{"{"}</p>
                        <p>"Message": {"{"}</p>
                        <p>"lvn-stats-voice": 1,</p>
                        <p>"lvn-stats-voice-list": [</p>
                        <p>{"{"}</p>
                        <p>"call_data": "Wed, 26 Oct 2022 20:38:56 GMT",</p>
                        <p>"call_data_f": "2022-10-26 20:38:56",</p>
                        <p>"cost": 0.0,</p>
                        <p>"country_a": "Poland",</p>
                        <p>"country_b": "Poland",</p>
                        <p>"duration": 0.0,</p>
                        <p>"id": 1,</p>
                        <p>"network_a": "Poland - Mobile - PLUS",</p>
                        <p>"network_b": "Poland - Mobile - PLUS",</p>
                        <p>"num_a": "48697123456",</p>
                        <p>"num_b": "48697123456",</p>
                        <p>"num_v": "48459987654",</p>
                        <p>"serial_cdr": 14</p>
                        <p>{"}"}</p>
                        <p>],</p>
                        <p>"status": "ok",</p>
                        <p>"timestamp": "2023-01-20 12:27:15.781180",</p>
                        <p>"x-date-end": "2023-01-19",</p>
                        <p>"x-date-start": "2022-01-16",</p>
                        <p>"x-num_a": "48697123456",</p>
                        <p>"x-num_b": "48697123456",</p>
                        <p>"x-num_v": "48459987654"</p>
                        <p>{"}"},</p>
                        <p>"Success": true</p>
                        <p>{"}"}</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Resp. description:</td>
                        <td>
                        <p>
                            lvn-stats-voice: number of found records
                            <br />
                            lvn-stats-voice-list: list of found records
                        </p>
                        <p>status: ok = service is up and running and</p>
                        <p>
                            timestamp: timestamp of response
                            <br />
                            x-&lt;search-field&gt;: &lt;search-field&gt; value (if provided)
                            <br />
                            success: true = response returned OK
                        </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h1 id="api-request-get-sms-statistics-data.">
                    <strong>API request: get SMS statistics data.</strong>{" "}
                </h1>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Request:</td>
                        <td>POST http://127.0.0.1/lvn_stats_sms</td>
                    </tr>
                    <tr className="even">
                        <td>Req. description:</td>
                        <td>
                        <p>
                            Get voice calls statistics data based on filter params:
                            <br />
                            - date_start - MANDATORY
                            <br />- date_end - MANDATORY
                        </p>
                        <p>- num_a (calling party) - OPTIONAL</p>
                        <p>
                            - num_v (virtual number – binded with num_b) - OPTIONAL
                            <br />- num_b (called party – binded with num_v) – OPTIONAL
                        </p>
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>cURL snippet:</td>
                        <td>
                        <p>
                            curl --location --request POST 'http://127.0.0.1/lvn_stats_sms' \
                        </p>
                        <p>--header 'Content-Type: application/json' \</p>
                        <p>--data-raw '{"{"}</p>
                        <p>"username": "&lt;user&gt;",</p>
                        <p>"password": "&lt;pass&gt;",</p>
                        <p>"date_start": "2022-01-16",</p>
                        <p>"date_end": "2023-01-19",</p>
                        <p>"num_b": "48880123456",</p>
                        <p>"num_v": "48459050069",</p>
                        <p>"num_a": "486031234567"</p>
                        <p>{"}"}'</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Body description:</td>
                        <td>
                        <p>
                            date_start: period starting datetime
                            <br />
                            date_end: period ending datetime
                        </p>
                        <p>num_a: calling party number - OPTIONAL</p>
                        <p>
                            num_v: virtual number – (binded with num_b) - OPTIONAL
                            <br />
                            num_b: called party number – (binded with num_v) – OPTIONAL
                        </p>
                        <p>
                            username: auth username
                            <br />
                            password: auth password
                        </p>
                        </td>
                    </tr>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        <p>STATUS 200 OK</p>
                        <p>{"{"}</p>
                        <p>"Message": {"{"}</p>
                        <p>"lvn-stats-sms": 1,</p>
                        <p>"lvn-stats-sms-list": [</p>
                        <p>{"{"}</p>
                        <p>"call_data": "Tue, 17 Jan 2023 10:43:52 GMT",</p>
                        <p>"call_data_f": "2023-01-17 10:43:52",</p>
                        <p>"cost": 0.0321,</p>
                        <p>"country_a": "Poland",</p>
                        <p>"country_b": "Poland",</p>
                        <p>"id": 25,</p>
                        <p>"id_sms": 25,</p>
                        <p>"network_a": "Poland - Mobile - PLUS",</p>
                        <p>"network_b": "Poland - Mobile - T-Mobile",</p>
                        <p>"num_a": "48603123456",</p>
                        <p>"num_b": "48880123456",</p>
                        <p>"num_v": "48459050069"</p>
                        <p>{"}"}</p>
                        <p>],</p>
                        <p>"status": "ok",</p>
                        <p>"timestamp": "2023-01-20 12:40:44.488502",</p>
                        <p>"x-date-end": "2023-01-19",</p>
                        <p>"x-date-start": "2022-01-16",</p>
                        <p>"x-num_a": "48603123456",</p>
                        <p>"x-num_b": "48880123456",</p>
                        <p>"x-num_v": "48459050069"</p>
                        <p>{"}"},</p>
                        <p>"Success": true</p>
                        <p>{"}"}</p>
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Resp. description:</td>
                        <td>
                        <p>
                            lvn-stats-sms: number of found records
                            <br />
                            lvn-stats-sms-list: list of found records
                        </p>
                        <p>status: ok = service is up and running and</p>
                        <p>
                            timestamp: timestamp of response
                            <br />
                            x-&lt;search-field&gt;: &lt;search-field&gt; value (if provided)
                            <br />
                            success: true = response returned OK
                        </p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <h1 id="error-response-list.">Error response list.</h1>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 400 BAD REQUEST
                        <br />
                        {"{"}
                        <br />
                        "Message": "Missing input post data.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>Mandatory input data not POSTed.</td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 400 BAD REQUEST
                        <br />
                        {"{"}
                        <br />
                        "Message": "Wrong virt_num format.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        Virtual number wrong format.
                        <br />
                        Allowed number format is CC+CN, example: 48123123123
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 400 BAD REQUEST
                        <br />
                        {"{"}
                        <br />
                        "Message": "Wrong real_num format.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        Real number wrong format.
                        <br />
                        Allowed number format is CC+CN, example: 48123123123
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 402 AUTH FAILED
                        <br />
                        {"{"}
                        <br />
                        "Message": "Auth failed!",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        Authorization failed.
                        <br />
                        Wrong username or password or credentials missed.
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 403 FORBIDDEN
                        <br />
                        {"{"}
                        <br />
                        "Message": "Wrong login/password",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>Wrong login/password. Check API’s credentials.</td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 404 NOT FOUND
                        <br />
                        {"{"}
                        <br />
                        "Message": "Resource NNN NOT found. Please contact with your account
                        manager",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        There are no matching tarrif/packet ID eSIM available.
                        <br />
                        Check tarrif_id field in request or contact your account manager.
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 500 INTERNAL SERVER ERROR
                        <br />
                        {"{"}
                        <br />
                        "Message": "Internal server error.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        Internal system error.
                        <br />
                        Contact your accound manager.
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 500 INTERNAL SERVER ERROR
                        <br />
                        {"{"}
                        <br />
                        "Message": "Database internal error.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        Internal database error.
                        <br />
                        Contact your accound manager.
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 500 INTERNAL SERVER ERROR
                        <br />
                        {"{"}
                        <br />
                        "Message": "Database internal error.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>Internal database error. Contact your accound manager.</td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 500 INTERNAL SERVER ERROR
                        <br />
                        {"{"}
                        <br />
                        "Message": "Virtual number already in database!.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        While inserting new bindings: virtual number already in database.
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 500 INTERNAL SERVER ERROR
                        <br />
                        {"{"}
                        <br />
                        "Message": "Database internal error while checking data.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        <p>Internal database error.</p>
                        <p>Contact your accound manager.</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 500 INTERNAL SERVER ERROR
                        <br />
                        {"{"}
                        <br />
                        "Message": "Database internal error while inserting data.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        <p>Internal database error.</p>
                        <p>Contact your accound manager.</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <colgroup>
                    <col style={{ width: "23%" }} />
                    <col style={{ width: "76%" }} />
                    </colgroup>
                    <tbody>
                    <tr className="odd">
                        <td>Response:</td>
                        <td>
                        STATUS: 500 INTERNAL SERVER ERROR
                        <br />
                        {"{"}
                        <br />
                        "Message": "Binding does not exist!.",
                        <br />
                        "Success": false
                        <br />
                        {"}"}
                        </td>
                    </tr>
                    <tr className="even">
                        <td>Description:</td>
                        <td>
                        While binding deleting.
                        <br />
                        Specified binding does not exists in database.
                        <br />
                        Check binding and try again
                        </td>
                    </tr>
                    </tbody>
                </table>
                </div>

        </div>
    )
}