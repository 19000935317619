import React from "react";
import logo from '../images/logo.svg'
import envelope from '../images/envelope.png'
import PolishFlag from '../images/polish-flag.png'
import EnglishFlag from '../images/english-flag.png'

export default function Header(props) {
    function handleLanguageFlag () {
        if (props.selectedLanguage === "English") {
            return <React.Fragment><img src={PolishFlag} className="header-language-flag" alt="Select display language" onClick={() => props.handleLanguageSelectionClick("Polish")}/></React.Fragment>
            
        } else if (props.selectedLanguage === "Polish") {
            return <React.Fragment><img src={EnglishFlag} className="header-language-flag" alt="Wybierz język wyświetlania strony" onClick={() => props.handleLanguageSelectionClick("English")}/></React.Fragment>
        } 
    }


    return (
        <div className="header">
            <div className="header-top">
                <a className="header-top-email" href="mailto:dok@nextmobile.pl"><img src={envelope} className="header-top-envelope" alt="email: dok@nextmobile.pl"/>dok@nextmobile.pl</a>
                <div className="header-language-icon">
                   <div>{handleLanguageFlag()}</div>
                </div>
            </div>
            <div className="header-bottom">
                <img src={logo} className="header-logo" alt="logo" />
                <p className="header-title">Virtual Numbers Dashboard</p>
            </div>
        </div>
    )
}